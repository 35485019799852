import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';

interface IAlertItemProps {
  id: number;
  title: string;
  status: boolean;
  time: Date;
}

export function AlertItem({ id, title, status, time }: IAlertItemProps) {
  const navigate = useNavigate();

  function onAlertClick() {
    navigate(`/alerts-history/${id}`);
  }

  const ItemContainer = styled.div`
    display: grid;
    grid-template-columns: 1.3fr 1.3fr 1fr auto;
    align-items: center;
    cursor: pointer;
    gap: 8px;
  `;

  const Title = styled.span`
    font-weight: 400;
    color: var(--grey-600);
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  const Time = styled.span`
    color: var(--grey-400);
    padding: 12px;
    margin-left: 10px;
    font-size: 0.875rem;
  `;

  const Status = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 400;
    width: 40%;
    color: ${status ? 'var(--green-900)' : 'var(--red-900)'};
    background-color: ${status ? 'var(--green-50)' : 'var(--red-50)'};
  `;

  const formattedTime = formatDistanceToNow(new Date(time));

  return (
    <ItemContainer onClick={onAlertClick}>
      <Title>{title}</Title>
      <Time>{formattedTime}</Time>
      <Status>{status ? 'Active' : 'Inactive'}</Status>
      <RightOutlined style={{ color: 'var(--grey-950)', justifySelf: 'end' }} />
    </ItemContainer>
  );
}
