import {
  HorizontalAlignedContainer,
  ModalFooterContainer,
  VerticalContainer,
} from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TGroupExtended } from '@app/types/groupExtended';
import { Modal, ModalFuncProps } from 'antd';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { TGroupPermission } from '@app/types/groupPermission';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { TPermission } from '@app/types/groupPermission';
import { CheckBox } from '@app/components/header/components/searchDropdown/searchOverlay/SearchFilter/SearchFilter.styles';
import RecommendationScoping from '../recommendation-scoping/components/RecommendationScoping';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import { useGetGroup } from '@app/api/groups.api';

const StyledModal = styled(Modal)`
  min-width: 60vw;
  .ant-modal-body {
    padding: 16px 24px 24px 24px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0px;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--grey-950);
  flex-basis: 35%;
  margin-bottom: 8px;
`;

const CategoryLabel = styled.span`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  color: #272727;
  flex-basis: 35%;
  align-self: flex-start;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid var(--grey-200)')};
  border-radius: 4px;
  padding: 12px;
  outline: none;
`;

const TextArea = styled.textarea<{ hasError?: boolean }>`
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid var(--grey-200)')};
  border-radius: 4px;
  padding: 12px;
  outline: none;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 0.875rem;
  font-weight: 400;
`;

const CancelButton = styled.button`
  border: 1px solid var(--blue-200);
  border-radius: 4px;
  background-color: transparent;
  padding: 12px 28px;
  color: var(--blue-800);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
`;

const CreateButton = styled.button`
  border: ${(props) => (props.disabled ? '1px solid var(--grey-100)' : '1px solid var(--blue-600)')};
  border-radius: 4px;
  background-color: ${(props) => (props.disabled ? 'var(--grey-100)' : 'var(--blue-600)')};
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PermissionsContainer = styled.div`
  border: 1px solid var(--grey-100);
  background-color: #fafafa;
  border-radius: 4px;
  padding: 12px;
`;

const PermissionInputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Description = styled.span`
  font-size: 1rem;
  color: var(--grey-600);
`;

export type TGroupForm = {
  id?: string;
  name: string;
  description: string;
  creationTime: Date;
  lastUpdatedTime: Date;
  status: number;
  accountId: string;
  numberOfUsers?: string;
  permissions?: number[];
  recommendationScopingEnabled: boolean;
  recommendationsFilter?: string;
};

interface IGroupProps extends ModalFuncProps {
  isEdit?: boolean;
  group?: TGroupExtended;
  error?: string | null;
  groupPermissions: TGroupPermission[];
  setRecommendationsFilter?: (filter: string) => void;
}

const GroupForm = ({
  isEdit,
  group,
  error,
  groupPermissions: categories,
  setRecommendationsFilter,
  ...modalProps
}: IGroupProps) => {
  const [localRecommendationsFilter, setLocalRecommendationsFilter] = useState<string | undefined>(undefined);

  const { data: groupData } = useGetGroup(Number(group?.id) || 0);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<TGroupForm>({ mode: 'onBlur' });

  const recommendationScopingEnabled = watch('recommendationScopingEnabled', false);
  const hasName = watch('name', '');
  const hasDescription = watch('description', '');

  const onFilterChange = (filter: string) => {
    const filterString = filter.split('&')?.find((f) => f.includes('$filter')) || '';
    setRecommendationsFilter?.(filterString);
    setLocalRecommendationsFilter(filterString);
  };

  useEffect(() => {
    if (modalProps.open && !!group && !!groupData) {
      reset({
        id: group.id,
        name: group.name,
        description: group.description,
        accountId: group.accountId,
        numberOfUsers: group.numberOfUsers,
        permissions: groupData.permissions?.map((p: TPermission) => p.id) || [],
        recommendationScopingEnabled: group.recommendationScopingEnabled,
      });
      setLocalRecommendationsFilter(group.recommendationsFilter || undefined);
    } else if (modalProps.open && !group) {
      reset({
        id: undefined,
        name: '',
        description: '',
        accountId: '',
        numberOfUsers: '0',
        permissions: [],
        recommendationScopingEnabled: undefined,
      });
      setLocalRecommendationsFilter(undefined);
    }
  }, [modalProps.open, groupData, reset, isEdit]);

  const onSubmit: SubmitHandler<TGroupForm> = (data) => {
    modalProps.onOk?.({ ...data, recommendationsFilter: localRecommendationsFilter });
  };

  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  const handleCheckboxChange = (permissions: TPermission[], checkedValues: CheckboxValueType[]) => {
    const currentPermissions = getValues('permissions') || [];
    const newPermissions = [
      ...Array.from(
        new Set([...currentPermissions.filter((v) => !permissions.some((p) => p.id === v)), ...checkedValues]),
      ),
    ];
    setValue('permissions', newPermissions as number[]);
  };

  const handleSingleCheckboxChange = (permissions: TPermission[], checkedValues: CheckboxValueType[]) => {
    const currentPermissions = getValues('permissions') || [];
    const selectedPermission = checkedValues.length > 0 ? [checkedValues[checkedValues.length - 1]] : [];

    // Filter out permissions that are not in the current group
    const filteredPermissions = currentPermissions.filter((perm) => !permissions.some((p) => p.id === perm));

    // Combine the filtered permissions with the newly selected permission
    const newPermissions = [...filteredPermissions, ...(selectedPermission as number[])];

    setValue('permissions', newPermissions);
  };

  return (
    <StyledModal
      {...modalProps}
      destroyOnClose
      title={
        <span style={{ fontSize: '1.5rem', fontWeight: '500', color: 'var(--grey-950)' }}>
          {isEdit ? 'Edit group' : 'Create a new group'}
        </span>
      }
      closeIcon={<CloseOutlined style={{ color: 'gray' }} />}
      footer={
        <ModalFooterContainer>
          <CancelButton type="button" onClick={handleCancel}>
            Cancel
          </CancelButton>
          <CreateButton type="submit" disabled={!hasName || !hasDescription} onClick={handleSubmit(onSubmit)}>
            {isEdit ? 'Update' : 'Create'}
          </CreateButton>
        </ModalFooterContainer>
      }
    >
      <Description>Define the actions users in this group can perform</Description>
      <FormContainer>
        <VerticalContainer>
          <FieldLabel>Group Name:</FieldLabel>
          <InputContainer>
            <FieldInput defaultValue={group?.name} {...register('name', { required: 'Name is required' })} />
            {!!errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
          </InputContainer>
        </VerticalContainer>

        <VerticalContainer>
          <FieldLabel>Description:</FieldLabel>
          <InputContainer>
            <TextArea
              defaultValue={group?.description}
              {...register('description', { required: 'Description is required' })}
            />
            {!!errors.description && <ErrorMessage>{errors.description.message}</ErrorMessage>}
          </InputContainer>
        </VerticalContainer>
        <CategoryLabel>Allowed actions:</CategoryLabel>
        <PermissionsContainer style={{ marginBottom: '20px' }}>
          <PermissionInputContainer>
            <Controller
              name="permissions"
              control={control}
              render={({ field }) => (
                <>
                  {categories &&
                    categories.map((category, index) => (
                      <CategoryContainer key={index}>
                        <FieldLabel style={{ flexBasis: 0, marginBottom: 10 }}>{category.displayName}</FieldLabel>
                        {category.allowMultiSelect ? (
                          <Checkbox.Group
                            {...field}
                            value={field.value}
                            onChange={(value) => handleCheckboxChange(category.groupPermissions, value)}
                          >
                            {category.groupPermissions.map((permission, pIndex) => {
                              const cleanedPermission =
                                permission.description.split('-')[1]?.trim() || permission.description;
                              return (
                                <CheckboxContainer key={pIndex}>
                                  <Checkbox value={permission.id}>{cleanedPermission}</Checkbox>
                                </CheckboxContainer>
                              );
                            })}
                          </Checkbox.Group>
                        ) : (
                          <Checkbox.Group
                            {...field}
                            value={field.value}
                            onChange={(value) => handleSingleCheckboxChange(category.groupPermissions, value)}
                          >
                            {category.groupPermissions.map((permission, pIndex) => {
                              const cleanedPermission =
                                permission.description.split('-')[1]?.trim() || permission.description;

                              return (
                                <CheckboxContainer key={pIndex}>
                                  <Checkbox value={permission.id}>{cleanedPermission}</Checkbox>
                                </CheckboxContainer>
                              );
                            })}
                          </Checkbox.Group>
                        )}
                      </CategoryContainer>
                    ))}
                </>
              )}
            />
          </PermissionInputContainer>
        </PermissionsContainer>

        <HorizontalAlignedContainer>
          <FieldLabel>Restrict Recommendation Access:</FieldLabel>
          <InputContainer>
            <Controller
              control={control}
              name="recommendationScopingEnabled"
              render={({ field }) => (
                <CheckBox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  style={{ marginBottom: 8 }}
                />
              )}
            />
          </InputContainer>
        </HorizontalAlignedContainer>

        {recommendationScopingEnabled && (
          <RecommendationScoping onFilterChange={onFilterChange} initialFilter={localRecommendationsFilter} />
        )}
      </FormContainer>
    </StyledModal>
  );
};

export default GroupForm;
