import { useState } from 'react';
import { Spin, Radio, Typography, Select } from 'antd';
import styled from 'styled-components';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { useEmailGroupInput } from './EmailGroupInput/useEmailGroupInput';
import { useInviteUsersWithGroup } from '@app/api/user.api';
import { SemiCirleCheckIcon } from '@app/components/icons';
import { CenterContainer, VerticalContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { CaretDownOutlined, CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { TGroupExtended } from '@app/types/groupExtended';
import { Dropdown, Space } from 'antd';
import type { MenuProps, RadioChangeEvent } from 'antd';
import { getEnumValue } from '@app/services/enum.service';
import EmailRecipients from '@app/components/common/EmailRecipients/EmailRecipients';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const FieldLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 18px;
  color: var(--grey-950);
`;

const ApplyButton = styled.button`
  border: ${(props) => (props.disabled ? '1px solid var(--grey-100)' : '1px solid var(--blue-600)')};
  border-radius: 4px;
  background-color: ${(props) => (props.disabled ? 'var(--grey-100)' : 'var(--blue-600)')};
  padding: 12px 34px;
  color: ${(props) => (props.disabled ? 'var(--grey-300)' : 'white')};
  font-size: 16px;
  font-weight: 500;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 20%;
`;
const CancelButton = styled.button`
  border: 1px solid var(--blue-200);
  border-radius: 4px;
  background-color: transparent;
  padding: 12px 34px;
  color: var(--blue-800);
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  width: 20%;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin: 10px 0px;
`;

const Message = styled.span`
  font-size: 32px;
  font-weight: 800;
  line-height: 50px;
  color: #272727;
  text-align: center;
`;

const DropdownContainer = styled.div`
  width: 100%;
  border: 1px solid var(--grey-200);
  border-radius: 4px;
`;

const Title = styled.span`
  color: var(--grey-950);
  font-size: 0.75rem;
  font-weight: 500;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-checked .ant-radio-inner {
    border-color: var(--blue-500) !important;
  }

  .ant-radio-checked .ant-radio-inner::after {
    background-color: var(--blue-500) !important;
  }

  .ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--blue-500) !important;
  }
`;

interface IModalFooterProps {
  onCancel?: () => void;
  onApply?: () => void;
  onApplyDisabled?: boolean;
}

const ModalFooter = ({ onCancel, onApply, onApplyDisabled }: IModalFooterProps) => {
  return (
    <FooterContainer>
      <CancelButton onClick={() => onCancel?.()}>Cancel</CancelButton>
      <ApplyButton disabled={onApplyDisabled} onClick={() => onApply?.()}>
        Invite
      </ApplyButton>
    </FooterContainer>
  );
};

interface IInviteUsersModalProps extends ModalFuncProps {
  isLoading?: boolean;
  onInviteSuccess?: () => void;
  groups?: TGroupExtended[];
}

export default function InviteUsersModal({ onInviteSuccess, groups, ...modalProps }: IInviteUsersModalProps) {
  const { mutateAsync: inviteUsersWithGroup, isLoading: isInviteUsersLoading } = useInviteUsersWithGroup();
  const [isSuccess, setIsSuccess] = useState(false);
  const emailGroupInputProps = useEmailGroupInput();
  const [role, setRole] = useState(2);
  const [selectedGroup, setSelectedGroup] = useState<TGroupExtended | null>(null);
  const [isAdminRole, setIsAdminRole] = useState(false);
  const [isSelectOpen, setSelectOpen] = useState(false);
  const { Text } = Typography;

  const handleDropdownVisibleChange = (open: boolean) => {
    setSelectOpen(open);
  };
  const onClick = (e: any) => {
    const group = groups?.find((g) => g.id.toString() === e.key);
    setSelectedGroup(group || null);
  };

  const handleInviteUsers = async () => {
    const invites = emailGroupInputProps.emails.map((email) => ({
      email: email.trim(),
      role,
    }));

    await inviteUsersWithGroup({
      invites,
      groupId: role === getEnumValue('UserRole', 'Admin') ? null : Number(selectedGroup?.id),
    });

    setIsSuccess(true);
    onInviteSuccess?.();
  };

  const handleCloseModal = () => {
    setIsSuccess(false);
    setRole(2);
    setIsAdminRole(false);
    setSelectedGroup(null);
    emailGroupInputProps.clearEmails();
    modalProps.onCancel?.();
  };

  const handleRoleChange = (e: RadioChangeEvent) => {
    setRole(e.target.value);
    setIsAdminRole(e.target.value === 1);
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={
        <span style={{ fontSize: '1.5rem', fontWeight: '500', color: 'var(--grey-950)' }}>
          {!isInviteUsersLoading && isSuccess ? null : 'Invite Users'}
        </span>
      }
      destroyOnClose={true}
      closeIcon={<CloseOutlined style={{ color: 'gray', fontSize: '16px' }} />}
      onCancel={handleCloseModal}
      footer={
        isInviteUsersLoading ? (
          <FooterContainer>
            <Spin />
          </FooterContainer>
        ) : isSuccess ? (
          <FooterContainer>
            <ApplyButton onClick={handleCloseModal}>Continue</ApplyButton>
          </FooterContainer>
        ) : (
          <ModalFooter
            onCancel={handleCloseModal}
            onApply={handleInviteUsers}
            onApplyDisabled={emailGroupInputProps.emails.length === 0}
          />
        )
      }
    >
      {isSuccess ? (
        <CenterContainer
          style={{
            gap: '16px',
            marginTop: '60px',
          }}
        >
          <SemiCirleCheckIcon />
          <Message>Users Are Invited!</Message>
        </CenterContainer>
      ) : (
        <Container>
          <FieldContainer>
            <FieldLabel>User Email Addresses:</FieldLabel>
            <EmailRecipients {...emailGroupInputProps} />
          </FieldContainer>

          <FieldContainer>
            <FieldLabel>Assigned Role For New Users:</FieldLabel>
            <StyledRadioGroup name="radiogroup" value={role}>
              <Radio value={1} onChange={handleRoleChange}>
                <VerticalContainer>
                  <Title>Administrator</Title>
                  <Text type="secondary" style={{ fontSize: '0.75rem', color: 'var(--grey-600)' }}>
                    Users with this role have the highest permissions level and can perform all actions, including
                    account-level settings configurations
                  </Text>
                </VerticalContainer>
              </Radio>

              <Radio value={2} onChange={handleRoleChange}>
                <VerticalContainer>
                  <Title>User</Title>
                  <Text type="secondary" style={{ fontSize: '0.75rem', color: 'var(--grey-600)' }}>
                    Permissions for this role are defined according to the permissions assigned to the user’s group
                  </Text>
                </VerticalContainer>
              </Radio>
            </StyledRadioGroup>
          </FieldContainer>

          {isAdminRole ? null : (
            <FieldContainer>
              <FieldLabel>Assigned Group For New Users:</FieldLabel>
              <DropdownContainer>
                <Select
                  placeholder="No Group"
                  value={selectedGroup?.id?.toString()}
                  onChange={onClick}
                  style={{ width: '100%', fontSize: '0.75rem', color: 'var(--grey-600)' }}
                  bordered={false}
                  suffixIcon={<DownOutlined rotate={isSelectOpen ? 180 : 0} />}
                  onDropdownVisibleChange={handleDropdownVisibleChange}
                >
                  {groups?.map((group) => (
                    <Select.Option key={group.id.toString()} value={group.id.toString()}>
                      {group.name}
                    </Select.Option>
                  ))}
                </Select>
              </DropdownContainer>
            </FieldContainer>
          )}
        </Container>
      )}
    </Modal>
  );
}
