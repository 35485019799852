import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useSetCompliantRecommendation } from '@app/api/recommendation.api';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { useGetManyRecommendationExtended } from './hooks/useGetRecommendationExtended';
import { Spin } from 'antd';
import { ModalFooterContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { BaseCancelButton, BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalMessage = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: var(--grey-600);
`;

const FieldLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--grey-950);
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

export type TAssignRecommendationForm = {
  notes?: string;
};

interface IMarkComplianceModalProps extends ModalFuncProps {
  recommendationIds: number[];
  recommendations?: TAccountRecommendationExtended[];
  complianceAction: 'mark-compliant' | 'mark-non-compliant';
}

export default function MarkComplianceModal({
  complianceAction,
  recommendationIds,
  recommendations,
  ...modalProps
}: IMarkComplianceModalProps) {
  const { recommendations: fetchedRecommendations, isLoading: isRecommendationsLoading } =
    useGetManyRecommendationExtended({
      recommendationIds,
      recommendationsProp: recommendations,
    });

  const { mutateAsync: setCompliantRecommendation, isLoading } = useSetCompliantRecommendation();

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<TAssignRecommendationForm>({
    mode: 'onBlur',
  });

  const complianceTitle = useMemo(() => {
    return complianceAction === 'mark-compliant' ? 'Compliant' : 'Non-Compliant';
  }, [modalProps.open, recommendations]);

  const handleAssign: SubmitHandler<TAssignRecommendationForm> = async (data) => {
    console.log(fetchedRecommendations);
    const payload = {
      accountRecommendationIds: fetchedRecommendations.map((d) => d.id),
      compliant: complianceAction === 'mark-compliant' ? true : false,
      notes: data.notes,
    };
    const response = await setCompliantRecommendation(payload);

    if (response.status === 201) {
      reset();
      modalProps.onOk?.();
    }
  };

  const handleCloseModal = () => {
    reset();
    modalProps.onCancel?.();
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={`Mark Recommendations as ${complianceTitle}`}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      footer={null}
    >
      {isRecommendationsLoading ? (
        <Spin />
      ) : (
        <>
          <FieldContainer>
            <ModalMessage>
              {`Add notes to this recommendation as you wish and save to mark it as ${complianceTitle.toLowerCase()}?`}
            </ModalMessage>
          </FieldContainer>

          <FormContainer onSubmit={handleSubmit(handleAssign)}>
            <FieldContainer>
              <FieldLabel>Notes:</FieldLabel>
              <InputContainer>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field }) => (
                    <HtmlEditor initialValue={field.value || ''} onChange={(content) => setValue('notes', content)} />
                  )}
                />
              </InputContainer>
            </FieldContainer>

            <ModalFooterContainer>
              <BaseCancelButton type="button" onClick={modalProps.onCancel}>
                Cancel
              </BaseCancelButton>
              <BaseCTAButton htmlType="submit" disabled={isLoading} style={{ padding: '12px 32px' }}>
                Save
              </BaseCTAButton>
            </ModalFooterContainer>
          </FormContainer>
        </>
      )}
    </Modal>
  );
}
