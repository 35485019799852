import styled from 'styled-components';

import MicrosoftIcon from '@app/assets/images/MicrosoftIcon.png';
import { StyledButton } from '@app/components/common/StyledButton/StyledButton';
import { signUp } from '@app/api/auth.api';
import { Link } from 'react-router-dom';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';

const Title = styled.span`
  font-size: 2rem;
  font-weight: ${FONT_WEIGHT.medium};
  color: #272727;
  margin-top: 1.5rem;
`;

const Description = styled.span`
  padding: 0px 25px;
  text-align: center;
  font-size: 16px;
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--grey-700);
  margin-bottom: 0.5rem;
`;

const LoginText = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: #6d6d6d;
  margin-top: 0.5rem;
`;

const SocialLogin = styled(StyledButton)`
  width: 100%;
  border-radius: 6px;
  border: 1px solid #d1d1d1;
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--grey-400);
  padding: 12px 0px;
  width: 500px;
  margin-top: 1rem;
`;

const Icon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const handleSignUp = async () => {
  const params = new URLSearchParams(window.location.search);
  const invitingUserId = params.get('invite');
  const signUpData = {
    invite: invitingUserId ?? undefined,
  };
  const url = await signUp(signUpData);
  window.location.href = url;
};

export function PersonalDetails() {
  const params = new URLSearchParams(window.location.search);
  const error = params.get('error');

  return (
    <>
      <Title>Admin User Setup</Title>
      <Description>Note that you need to have global administrator in your azure tenant for system setup</Description>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <SocialLogin variant="outlined" color="dark" size="large" onClick={handleSignUp}>
        <Icon src={MicrosoftIcon} alt="Microsoft Icon" />
        Sign up with Microsoft
      </SocialLogin>
      <LoginText>
        Already a member?{' '}
        <Link to="/auth/login" style={{ color: 'var(--blue-600)' }}>
          Log in
        </Link>
      </LoginText>
    </>
  );
}
