import { useGetModelItems } from '@app/api/table.api';
import { TChangeLogs } from '@app/types/changelogs';
import { eventMarkerColor, TTimelineType } from '@app/components/tables/AntdTableWrapper/components/Timeline';
import { useMemo } from 'react';
import styled from 'styled-components';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import { formatDateToDDMMYYYY } from '@app/utils/utils';
import { useResponsive } from '@app/hooks/useResponsive';
import { ResponsiveContainer, ScatterChart, CartesianGrid, XAxis, YAxis, Scatter, Dot } from 'recharts';
import { TEventLogs } from '@app/pages/RecommendationHistory';
import { Popover, Spin } from 'antd';
import { CenterContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import EventTooltip from './EventTooltip';

const ChartContainer = styled.div`
  background-color: white;
  margin-bottom: 2rem;
  padding: 16px 16px 0 16px;
  border: 1px solid var(--grey-100);
  border-radius: 8px;
  width: 100%;
  height: 340px;
  max-height: fit-content;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const LegendTitle = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  margin-right: 8px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${FONT_SIZE.xxs};
  color: var(--grey-600);
`;

const LegendCircle = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  font-size: ${FONT_SIZE.xs};
  background-color: ${(props) => props.color};
  margin-right: 4px;
`;

interface IEventHistoryChartProps {
  startDate: Date;
  endDate: Date;
  additionalQueryParams?: string;
  type: TTimelineType;
  onAdditionalDetailsClicked: (props: { changeLogDate: string; eventsText: TEventLogs }) => void;
}

export default function EventHistoryChart({
  startDate,
  endDate,
  additionalQueryParams,
  type,
  onAdditionalDetailsClicked,
}: IEventHistoryChartProps) {
  const { isBigScreen } = useResponsive();

  const BUBBLE_MIN_SIZE = 6;
  const BUBBLE_MAX_SIZE = isBigScreen ? 18 : 20;

  const dateRangeQueryParams = `$filter=date ge ${startDate.toISOString()} and date le ${endDate.toISOString()}${
    !!additionalQueryParams ? ` and ${additionalQueryParams}` : ''
  }&orderby=date asc`;

  const { data, isFetching } = useGetModelItems<TChangeLogs>({
    model: 'dailyChanges',
    queryParams: dateRangeQueryParams,
    customQueryKey: ['dailyChanges-odata', dateRangeQueryParams].join(','),
  });

  const chartData = useMemo(() => {
    const items = data?.items || [];
    return items
      .map((item) => {
        const totalChanges = getTotalChanges(item, type);
        return {
          date: new Date(item.date),
          formattedDate: formatDateToDDMMYYYY(new Date(item.date)),
          totalChanges: totalChanges.total,
          color: eventMarkerColor(item),
          item,
        };
      })
      .sort((a, b) => a.date.getTime() - b.date.getTime())
      .map(({ ...rest }) => ({
        ...rest,
        date: rest.formattedDate,
      }));
  }, [data, type]);

  function scaleMarkerSize(changeLog: TChangeLogs, timelineType: TTimelineType): number {
    const totalChanges = getTotalChanges(changeLog, timelineType);

    if (totalChanges.total <= 0) return BUBBLE_MIN_SIZE;

    const logScaledValue = Math.log10(totalChanges.total + 1);

    const markerSize = BUBBLE_MIN_SIZE + (logScaledValue * (BUBBLE_MAX_SIZE - BUBBLE_MIN_SIZE)) / Math.log10(100);

    return Math.min(markerSize, BUBBLE_MAX_SIZE);
  }

  const CustomMarker = ({ cx, cy, item }: { cx: number; cy: number; item: TChangeLogs }) => {
    if (!cx || !cy) {
      return null;
    }

    return (
      <Popover
        content={<EventTooltip type={type} item={item} handleAdditionalDetailsClicked={onAdditionalDetailsClicked} />}
        placement="top"
        trigger="hover"
      >
        <Dot
          pointerEvents="auto"
          cx={cx}
          cy={cy}
          r={scaleMarkerSize(item, type)}
          fill={eventMarkerColor(item, type)}
          strokeWidth={1}
        />
      </Popover>
    );
  };

  const xAxisInterval = chartData.length > 10 ? 2 : 0;
  const legends = useMemo(() => getLegends(type), [type]);

  return (
    <ChartContainer>
      {isFetching ? (
        <CenterContainer style={{ height: '100%' }}>
          <Spin />
        </CenterContainer>
      ) : (
        <>
          <LegendContainer>
            <LegendTitle>Recommendations</LegendTitle>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
              {legends.map((legend, idx) => (
                <LegendItem key={idx}>
                  <LegendCircle color={legend.color} />
                  <span>{legend.label}</span>
                </LegendItem>
              ))}
            </div>
          </LegendContainer>

          <ResponsiveContainer width="100%" height={300}>
            <ScatterChart margin={{ top: 20, right: 30, bottom: 15, left: -25 }}>
              <CartesianGrid strokeDasharray="20 20" stroke="var(--grey-100)" vertical={false} />
              <XAxis
                axisLine={false}
                dataKey="date"
                tick={{ fontSize: 12 }}
                tickLine={false}
                interval={xAxisInterval}
                padding={{ left: 20, right: 20 }}
              />
              <YAxis
                axisLine={false}
                dataKey="totalChanges"
                tick={{ fontSize: 12 }}
                tickLine={false}
                allowDecimals={false}
              />
              <Scatter name="Events" data={chartData} shape={(props: any) => <CustomMarker {...props} />} />
            </ScatterChart>
          </ResponsiveContainer>
        </>
      )}
    </ChartContainer>
  );
}

export function getLegends(type: TTimelineType): { label: string; color: string }[] {
  switch (type) {
    case 'all':
      return [
        { label: 'Improvements', color: 'green' },
        { label: 'Regressions', color: 'red' },
        { label: 'Both/Other', color: 'gray' },
      ];
    case 'configuration changes':
      return [
        { label: 'Improvements', color: 'green' },
        { label: 'Regressions', color: 'red' },
        { label: 'Both', color: 'gray' },
      ];
    case 'assignments':
      return [{ label: 'Assignments/Un-Assignments', color: 'gray' }];
    case 'exemptions':
      return [
        { label: 'Un-Exemptions', color: 'green' },
        { label: 'Exemptions', color: 'red' },
        { label: 'Both', color: 'gray' },
      ];
    default:
      return [];
  }
}

export function getTotalChanges(
  changeLog: TChangeLogs,
  timelineType: TTimelineType,
): {
  becameCompliantTotal: number;
  becameNonCompliantTotal: number;
  riskyNonCompliantTotal: number;
  markedExemptedTotal: number;
  markedNonExemptedTotal: number;
  assignmentsTotal: number;
  total: number;
} {
  const becameCompliantNum =
    timelineType !== 'all' && timelineType !== 'configuration changes'
      ? 0
      : parseInt(changeLog.becameCompliant, 10) || 0;

  const becameNonCompliantNum =
    timelineType !== 'all' && timelineType !== 'configuration changes'
      ? 0
      : parseInt(changeLog.becameNonCompliant, 10) || 0;

  const riskyNonCompliantNum =
    timelineType !== 'all' && timelineType !== 'configuration changes' ? 0 : parseInt(changeLog.riskyNonCompliant);

  const markedExemptedNum =
    timelineType !== 'all' && timelineType !== 'exemptions' ? 0 : parseInt(changeLog.markedExempted, 10) || 0;

  const markedNonExemptedNum =
    timelineType !== 'all' && timelineType !== 'exemptions' ? 0 : parseInt(changeLog.markedNonExempted, 10) || 0;

  const assignmentsNum =
    timelineType !== 'all' && timelineType !== 'assignments' ? 0 : parseInt(changeLog.assignments, 10) || 0;

  const total = becameCompliantNum + becameNonCompliantNum + markedExemptedNum + markedNonExemptedNum + assignmentsNum;

  return {
    becameCompliantTotal: becameCompliantNum,
    becameNonCompliantTotal: becameNonCompliantNum,
    riskyNonCompliantTotal: riskyNonCompliantNum,
    markedExemptedTotal: markedExemptedNum,
    markedNonExemptedTotal: markedNonExemptedNum,
    assignmentsTotal: assignmentsNum,
    total,
  };
}
