import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { useState } from 'react';
import styled from 'styled-components';
import { useSetExemptRecommendation } from '@app/api/recommendation.api';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { Checkbox, Select, Spin } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import TextArea from 'antd/lib/input/TextArea';
import { useGetManyRecommendationExtended } from './hooks/useGetRecommendationExtended';
import { HorizontalAlignedContainer, ModalFooterContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { BaseCancelButton, BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const FieldLabel = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: var(--grey-950);
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const NoBulletList = styled.ul`
  list-style-type: disc;
  padding: 0px 12px;
  margin: 0;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

interface IModalFooterProps {
  onCancel?: () => void;
  onApply?: () => void;
  onApplyDisabled?: boolean;
}

const reasonOptions = ['I use a third party tool', 'Risk Accepted', 'Other'];

interface ISetRecommendationExemptModalProps extends ModalFuncProps {
  recommendationIds: number[];
  recommendations?: TAccountRecommendationExtended[];
}

interface RecommendationListProps {
  recommendations: TAccountRecommendationExtended[];
}

function RecommendationList({ recommendations }: RecommendationListProps) {
  return (
    <FieldContainer>
      <FieldLabel style={{ marginBottom: '3px' }}>You selected to exempt the following recommendations:</FieldLabel>
      {recommendations.map((rec, index) => (
        <HorizontalAlignedContainer key={`rec-${index}`} style={{ gap: '8px' }}>
          <Checkbox checked={true} />
          <span style={{ fontSize: '0.875rem', fontWeight: '400', color: 'var(--grey-950)' }}>{rec.findings}</span>
        </HorizontalAlignedContainer>
      ))}
    </FieldContainer>
  );
}

export default function ExemptRecommendationModal({
  recommendationIds,
  recommendations,
  ...modalProps
}: ISetRecommendationExemptModalProps) {
  const { recommendations: fetchedRecommendations, isLoading } = useGetManyRecommendationExtended({
    recommendationIds,
    recommendationsProp: recommendations,
  });

  const { mutateAsync: setExempt } = useSetExemptRecommendation();

  const [customReason, setCustomReason] = useState<string>('');
  const [reasonOption, setReasonOption] = useState<string>(reasonOptions[0]);
  const [error, setError] = useState<string | null>();
  const [openSelect, setOpenSelect] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    // formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  const handleExempt = async () => {
    const setExemptRequest = {
      accountRecommendationIds: fetchedRecommendations.map((rec) => rec.id),
      exempt: true,
      reason: reasonOption !== 'Other' ? reasonOption : `Other: ${customReason}`,
    };
    const response = await setExempt(setExemptRequest);
    if (response.status !== 201) {
      setError(`Exemption Failed: ${response?.data?.message}`);
    } else {
      reset();
      setError(null);
      modalProps.onOk?.();
    }
  };

  const handleCloseModal = () => {
    reset();
    setError(null);
    modalProps.onCancel?.();
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={'Exempt Recommendation'}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      footer={null}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <RecommendationList recommendations={fetchedRecommendations} />
          <FormContainer onSubmit={handleSubmit(handleExempt)}>
            <FieldContainer>
              <FieldLabel style={{ fontSize: '0.75rem' }}>
                Please specify reason for exempting these recommendations:
              </FieldLabel>
              <InputContainer>
                <Controller
                  control={control}
                  name="reasonOption"
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={(value) => {
                        setReasonOption(value);
                        setOpenSelect(false);
                      }}
                      value={reasonOption}
                      open={openSelect}
                      onDropdownVisibleChange={(open) => setOpenSelect(open)}
                      suffixIcon={
                        openSelect ? (
                          <UpOutlined
                            onClick={() => {
                              setOpenSelect(false);
                            }}
                          />
                        ) : (
                          <DownOutlined
                            onClick={() => {
                              setOpenSelect(true);
                            }}
                          />
                        )
                      }
                    >
                      {reasonOptions.map((value, index) => (
                        <Select.Option key={index} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
              </InputContainer>
            </FieldContainer>
            {reasonOption === 'Other' && (
              <FieldContainer>
                <FieldLabel style={{ fontSize: '0.75rem' }}>Enter exemption reason</FieldLabel>
                <InputContainer>
                  <TextArea
                    placeholder="Enter your exemption reason here..."
                    {...register('customReason', { required: false })}
                    onChange={(event) => setCustomReason(event.target.value)}
                  />
                </InputContainer>
              </FieldContainer>
            )}

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <ModalFooterContainer>
              <BaseCancelButton type="button" onClick={modalProps.onCancel}>
                Cancel
              </BaseCancelButton>
              <BaseCTAButton
                htmlType="submit"
                disabled={reasonOption === 'Other' && (customReason == null || customReason.length === 0)}
                style={{ padding: '12px 32px' }}
              >
                Exempt
              </BaseCTAButton>
            </ModalFooterContainer>
          </FormContainer>
        </>
      )}
    </Modal>
  );
}
