import React from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import styled from 'styled-components';
import { getImpersonationToken } from '@app/services/localStorage.service';
import { FaEllipsisVertical, FaRegUser } from 'react-icons/fa6';

const Heading = styled.h1`
  font-size: 0.875em;
  text-align: start;
  margin: 0;
  color: var(--white);
`;

const SubHeading = styled.h1`
  font-size: 0.625em;
  text-align: start;
  margin: 0;
  color: #b0b0b0;
`;
const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;
const ProfileWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
`;

interface ProfileDropdownProps {
  isCollapsed?: boolean;
}

export const ProfileDropdown: React.FC<ProfileDropdownProps> = ({ isCollapsed }) => {
  const user = useAppSelector((state) => state.user.user);

  const impersonationToken = getImpersonationToken();

  return user ? (
    <BasePopover content={impersonationToken || <ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow}>
        <ProfileWrapper>
          <FaRegUser
            style={{
              height: '20px',
              width: '20px',
              marginRight: isCollapsed ? '0' : '15px',
            }}
          />
          {!isCollapsed && (
            <>
              <UserInfoWrapper>
                <Heading>{user.account.name}</Heading>
                <SubHeading>{user.email}</SubHeading>
              </UserInfoWrapper>
              <FaEllipsisVertical />
            </>
          )}
        </ProfileWrapper>
      </S.ProfileDropdownHeader>
    </BasePopover>
  ) : null;
};
