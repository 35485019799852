import { CloseOutlined } from '@ant-design/icons';
import { Modal, ModalFuncProps, Spin } from 'antd';
import { useMemo, useState } from 'react';

import { CenterContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';

import { useGetRecommendationStories } from '@app/api/story.api';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { useGetRecommendationExtended } from './hooks/useGetRecommendationExtended';
import OverviewSection from './RecommendationDetailsModalTabs/OverviewSection';
import PreRequisitesSection from './RecommendationDetailsModalTabs/PreRequisitesSection';
import RemediationSection from './RecommendationDetailsModalTabs/RemediationSection';
import TabsCompact from '@app/components/common/TabsCompact/TabsCompact';
import HistorySection from './RecommendationDetailsModalTabs/HistorySection';

interface IRecommendationDetailsModalProps extends ModalFuncProps {
  // recommendation: TAccountRecommendationExtended;
  recommendationId: number;
  recommendationProp?: TAccountRecommendationExtended;
  open: boolean;
  onClose?: () => void;
}

export default function RecommendationDetailsModal({
  recommendationId,
  recommendationProp,
  ...modalProps
}: IRecommendationDetailsModalProps) {
  const { recommendation, isLoading } = useGetRecommendationExtended({
    recommendationId,
    recommendationProp,
  });

  const [activeTab, setActiveTab] = useState(0);

  const { data: storyRecommendationResponse } = useGetRecommendationStories(recommendationId);

  const recommendationStories = useMemo(() => {
    if (!storyRecommendationResponse) return [];

    return storyRecommendationResponse;
  }, [storyRecommendationResponse]);

  const tabContent = useMemo(() => {
    if (!recommendation) return [];

    return [
      {
        title: 'Overview',
        content: <OverviewSection recommendation={recommendation} recommendationStories={recommendationStories} />,
        disabled: false,
      },
      {
        title: 'Requirements',
        content: <PreRequisitesSection recommendation={recommendation} activeTab={activeTab} />,
        disabled: false,
      },
      {
        title: 'Remediation steps',
        content: <RemediationSection recommendation={recommendation} />,
        disabled: false,
      },
      {
        title: 'History',
        content: (
          <HistorySection
            recommendationId={recommendationId.toString()}
            recommendationValidationMethod={recommendation?.validationMethod}
            accountRecommendationId={recommendation.id}
          />
        ),
        disabled: false,
      },
    ];
  }, [recommendation, activeTab]);

  return (
    <Modal
      title={<span style={{ fontSize: '1.25rem', fontWeight: '500' }}>Details</span>}
      {...modalProps}
      destroyOnClose={true}
      closeIcon={<CloseOutlined style={{ color: 'gray' }} />}
      footer={null}
      width="65vw"
    >
      {isLoading ? (
        <CenterContainer>
          <Spin />
        </CenterContainer>
      ) : (
        <TabsCompact tabContent={tabContent} activeTab={activeTab} setActiveTab={setActiveTab} />
      )}
    </Modal>
  );
}
