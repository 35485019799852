import styled from 'styled-components';

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HorizontalAlignedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpaceGrower = styled.div`
  flex-grow: 1;
`;

export const DashboardCenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

export const ModalFooterContainer = styled(HorizontalAlignedContainer)`
  gap: 10px;
  justify-content: end;
`;
